<template>
  <div class="hero-image" ref="heroImage" :style="styles">
    <img :src="heroImageSrc" :alt="alt" />
    <span class="hero-image__title" v-if="title">{{ title }}</span>
  </div>
</template>

<script>
import { onMounted, computed, ref } from "vue"

export default {
  name: 'HeroImage',
  props: {
    src: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: false
    },
    alt: {
      type: String,
      required: true
    },
    height: {
      type: String,
      default: '30vh'
    }
  },
  setup(props) {
    const heroImageSrc = ref(null)

    onMounted(() => {
      heroImageSrc.value = props.src
    })

    const styles = computed(() => {
      return {
        'height': props.height
      };
    });


    return {
      heroImageSrc,
      styles
    }
  }
}
</script>

<style lang="scss" scoped>
.hero-image {
  width: 100%;
  position: relative;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &__title {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 1rem;
    background-color: #3bace2;
    color: white;
    font-size: 2rem;
    font-weight: 700;
    text-align: center;
  }

  @media screen and (max-width: 768px) {
    &__title {
      font-size: 1.5rem;
    }
    
  }
}
</style>
